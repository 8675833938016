// Font Variables
$roboto-slab: 'Roboto Slab', serif;   
$open-sans: 'Open Sans', sans-serif;
$ibmplexsans: 'IBM Plex Sans';
$patau: 'Patua One', sans-serif;

// Colors
$color_1: #3b99e0;
$color_2: #79818a;
$background_color_1: #ffffff;
$background_color_2: white;
$background_color_3: #ffffff;
$background_color_4: #d4d4d4;  
$brizzly_green: #87c097;

$font_body: #212529;
$font_muted: rgb(101, 119, 134);
@import './styles/public.scss';
@import './styles/variables.scss';
@import './styles/darkmode.scss';

html {font-size: 100%;} /*16px*/

// Stripe
.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #dcdcdd;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-family: $ibmplexsans;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

// Main App Styles
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.main {
    /* to push the sticky footer down and show all content */
    margin-bottom: 66px;
    font-family: $ibmplexsans;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    max-height: 60px;
    z-index: 1000;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.top-navbar {
    background-color: $brizzly_green;
    border-bottom: 1px solid #a1a1a1;
}

// Post
.create-post {
    z-index: 3;
    position: relative;
    .post-btns {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 16px;
        }
    }
}

// Misc
// Alerts
.global-alert {
    position: fixed;
    width: 100%;
    bottom: 6rem;
    left: 0;
    .alert-inner {
        display: flex;
        justify-content: center;
    }
}

// Timeline
.timeline {
    position: relative;
    .refresh-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        text-align: center;
    }
}

.timeline-loader {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
}

// Tweet - wip
.tweet-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: .9em;
}

.tweet-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px !important;
    .tweet-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .action {
            width: 100px;
            >span {
                cursor: pointer;
            }
        }
        .liked {
            color: red;
            svg {
                // color: red;
            }
        }
    }
    .post-actions {
        display: flex;
        justify-content: space-around;
        .counter {
            margin-left: 8px;
        }
    }
}

.tweet-media {
    display: flex;
    justify-content: center;
}

.link-preview {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 8px;
    max-width: 500px;
    margin-top: 16px;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    .img {
        max-width: 500px;
    }
    img {
        width: 100%;
        height: 100%;
    }
    a {
        padding: 8px 0;
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
    .title {
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        color: $font_muted;
        &:hover {
            text-decoration: none;
        }
    }
    .description,
    .host {
        color: $font_muted;
        &:hover {
            text-decoration: none;
        }
    }
}

.tweet-text {
    font-size: 1.1em;
    margin-bottom: 0.3em;
}

.tweet-user {
    display: flex;
    align-items: center;
    img {
        max-width: 40px;
        border-radius: 50%;
        margin-top: 2px;
    }
    .name-box {
        margin-left: 8px;
    }
    .screenName {
        font-size: .9em;
    }
    .name {
        font-size: 1.1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        svg {
            margin-left: 8px;
        }
    }
}

.checkout {
    .credit-card {
        padding-top: 50px;
        margin-bottom: 40px;
        text-align: center;
        p {
            text-align: center;
            max-width: 420px;
            margin: auto;
            opacity: 0.7;
        }
        h1 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
        h2 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
        h3 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#Logo_FIXED {
    fill: #DAE3E9;
    margin-top: 0px;
     :hover {
        fill: #1da1f2;
    }
}

// alternate checkout style
.payment-form {
    padding-bottom: 50px;
    font-family: $ibmplexsans;
    .content {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
        background-color: $background_color_2;
    }
    .block-heading {
        padding-top: 50px;
        margin-bottom: 40px;
        text-align: center;
        p {
            text-align: center;
            /*			max-width: 420px; */
            margin: auto;
            opacity: 0.7;
        }
        h1 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
        h2 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
        h3 {
            margin-bottom: 1.2rem;
            color: $color_1;
        }
    }
    form {
        border-top: 2px solid #5ea4f3;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
        background-color: $background_color_3;
        padding: 0;
        margin: auto;
        }
        .title {
        font-size: 1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0.8em;
        font-weight: 600;
        padding-bottom: 8px;
    }
    .products {
        background-color: $background_color_4;
        padding: 25px;
        .item {
            margin-bottom: 1em;
            p {
                margin-bottom: 0.2em;
            }
        }
        .item-name {
            font-weight: 600;
            font-size: 0.9em;
        }
        .item-description {
            font-size: 0.8em;
            opacity: 0.6;
        }
        .price {
            float: right;
            font-weight: 600;
            font-size: 0.9em;
        }
        .total {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 10px;
            padding-top: 19px;
            font-weight: 600;
            line-height: 1;
        }
    }
    .card-details {
     /*   padding: 25px 25px 15px; */
        label {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 15px;
            color: $color_2;
            text-transform: uppercase;
        }
        button {
            margin-top: 0.6em;
            padding: 12px 0;
            font-weight: 600;
        }
        .promo-code {
            color: $color_1;
        }
    }
    .date-separator {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
    }
}

.payment-form.dark {
    background-color: $background_color_1;
    .block-heading {
        p {
            opacity: 0.8;
        }
    }
}

.ibmplexsans {
    font-family: $ibmplexsans;
}


.suggested-plan {
    cursor: pointer;
    &:hover,
    &.active {
        background-color: $brizzly_green;
        color: black;
        p {
            color: white;
        }
    }
}


/*
   * Custom translucent site header
   */

.site-header {
    background-color: rgba(0, 0, 0, .85);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    a {
        color: #999;
        transition: ease-in-out color .15s;
         :hover {
            color: #fff;
            text-decoration: none;
        }
    }
}


/*
   * Dummy devices (replace them with your own or something else entirely!)
   */

.product-device {
    position: absolute;
    right: 10%;
    bottom: -30%;
    width: 300px;
    height: 540px;
    background-color: #333;
    border-radius: 21px;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.product-device::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: rgba(255, 255, 255, .1);
    border-radius: 5px;
}

.product-device-2 {
    top: -25%;
    right: auto;
    bottom: 0;
    left: 5%;
    background-color: #e5e5e5;
}


/*
   * Extra utilities
   */

.flex-equal>* {
    -ms-flex: 1;
    flex: 1;
}

@media (min-width: 768px) {
    .flex-md-equal>* {
        -ms-flex: 1;
        flex: 1;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.timestamp {
    text-align: left;
    font-weight: 400;
    color: $color_2;
}

.patua {
    font-family: $patau;
}

.undone {
    /*	background-image: url("/undo-button.png");
	background-repeat: repeat;
	background-position: 100% 90%; */
}

.brizzly-back {
    background-color: $brizzly_green;
}

.brizzly-brown {
    background-color: #575757;
}

.brizzly-btn {
    border-color: $brizzly_green;
    color: $brizzly_green;
}

.brizzly-btn:hover {
    background-color: $brizzly_green;
    color: white;
    border-color: $brizzly_green;
}

.brizzly-btn:after {
    background-color: $brizzly_green;
    color: white;
    border-color: $brizzly_green;
}

// Firebase-GoogleAuth buttons styling
.firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-password.firebaseui-id-idp-button {
    border-radius: 10px;
    background-color: #333 !important;
    font-family: $ibmplexsans;
    font-weight: bold;
}

.firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-google.firebaseui-id-idp-button {
    border-radius: 10px;
    font-family: $ibmplexsans;
    font-weight: bold;
}

.firebaseui-tos.firebaseui-tospp-full-message {
    font-size: .9em;
    padding-top: 2px;
    line-height: 1.5em;
    font-family: $ibmplexsans;
}

.form-label {
    font-family: $ibmplexsans;
    font-weight: bolder;
    font-size: 1.4em;
}

.tweet-card {
    margin-left: 0px;
    margin-right: 0px;
}

.light {
    margin-top: 0;
}

/* Mobile styles */

@media (max-width: 480px) {
    .timestamp {
        font-size: .85em;
    }
    .name-box {
        .name {
            font-size: .9em;
    }
}
    .card-body {
        padding: 10px 10px 10px;
        font-size: .9em;
         button {
             margin-top: 2em;
         }
    }

    .tweet-bottom {
        .tweet-actions {
            font-size: .85em;
            }
        }
    }
   

@media (min-width: 576px) {
    .payment-form {
        .title {
            font-size: 1.2em;
        }
        .products {
            padding: 40px;
            .item-name {
                font-size: 1em;
            }
            .price {
                font-size: 1em;
            }
        }
        .card-details {
           padding: 40px 40px 30px;
            button {
                margin-top: 2em;
            }
        }
    }
}
